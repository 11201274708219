import * as React from 'react';
//import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
//import CssBaseline from '@mui/material/CssBaseline';
//import MuiDrawer from '@mui/material/Drawer';
//import Box from '@mui/material/Box';
//import MuiAppBar from '@mui/material/AppBar';
//import Toolbar from '@mui/material/Toolbar';
//import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
//import Divider from '@mui/material/Divider';
//import IconButton from '@mui/material/IconButton';
//import Badge from '@mui/material/Badge';
//import Container from '@mui/material/Container';
//import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import { Route, Routes } from "react-router-dom";
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
//import MenuIcon from '@mui/icons-material/Menu';
//import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import NotificationsIcon from '@mui/icons-material/Notifications';

// import { mainListItems, secondaryListItems } from './components/listitems';
import {NavBar} from '../components/NavBar'
import SignIn from '../pages/SignIn';
import Home from '../pages/home';
import Contact from '../pages/contact';
import Privacy from '../pages/privacy';
import Company from '../pages/company';
function Copyright(props) {
  return (
  <>
    <Typography variant="body2" color="text.secondary" align="center" style={{ marginBottom: 5, marginTop: 50 }} {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        GenaFlow
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      GenaFlow is a product of David Baker and Associates Corp.
    </Typography>
    <Typography variant="body2" color="text.secondary" align="center"  style={{ marginBottom: 50, marginTop: 10 }} {...props}>
       <Link color="inherit" href="/privacy">
        Privacy Policy
      </Link>{' '}
    </Typography>
  </>
  );
}

function DashboardContent() {
  return (
      <>
        <NavBar  width="100%" sx={{
           margin: '0',
           padding: "0"}}  />
         <Routes>
              <Route path='/Sign_In' element={<SignIn />}/>
              <Route exact path="/contact" element={<Contact />}  />
              <Route exact path="/privacy" element={<Privacy />}  />
              <Route exact path="/company" element={<Company />}  />
              <Route exact path="/*" element={<Home />}  />
          </Routes>
                  <Copyright sx={{ mt: 8, mb: 4 }} />
          </>
          );
}

export const MainFrame = () => {
  return <DashboardContent />;
}