import * as React from 'react';
// import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Markdown from 'react-markdown'
import gfm from 'remark-gfm'

const theme = createTheme();
// class Deposits extends React.Component {
class Privacy extends React.Component {

  handleSubmit = async (event) => {
    event.preventDefault();
  };
  render(){
  const pp_header = `# Privacy Policy`
  const pp_toc = `## Contents:
I. Policy\\
II. Data Collected\\
III. Data Processing\\
IV. Legal Basis for Processing\\
V. Sharing of Personal Data\\
VI. Cookies\\
VII. Federated Login\\
VIII. Notice of International Data Transfer\\
IX. Data Retention\\
X. Data Access, Review, Updates, and Corrections\\
XI. Data Security\\
XII. Data Collection from Minors\\
XIII. Additional Privacy Rights\\
XIV. Do-Not-Track\\
XV. California Privacy Rights\\
XVI. Privacy Policy Updates\\
XVII. Privacy Contact`
  const pp_main =`# GenaFlow Privacy Policy
Last Updated: 2023-12-06
## I. Policy
This Privacy Policy for GenaFlow, LLC. (“GenaFlow”, “we”, “us”, or “our”) describes how and why we might collect, store, use, and/or share (“process”) your information when you use the genaflow.com website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”), when visit, purchase, or otherwise use the GenaFlow application or any other service that we may provide (collectively, the “Services”) or otherwise engage with us.

This policy is intended to help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not access or otherwise use the Site, the Services, or otherwise engage with GenaFlow. If you still have any questions or concerns, please contact us at legal@genaflow.com.

a. Summary of Privacy Practices
What personal information do we process? When you interact with our Site or Services or otherwise interact with GenaFlow, we may process personal information depending on how you interact with us, our Services, and our Site, the choices you make, and the products and features you use.

Do we process any sensitive personal information? We do not process sensitive personal information.

Do we receive any information from third parties? We do not receive any information from third parties.

Why do we process your information? We process your information to provide, improve, and administer our Site and our Services, to communicate with you, for security and fraud prevention, and to comply with all relevant laws and regulations. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.

In what situations and with which parties do we share personal information? We use a number of third party data processors to store and process data on our behalf. These include our hosting providers, our payment processors, and our sales and marketing software providers, among others. We do not sell your information or provide it to others to use as their own.

How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be completely secure, so we cannot promise or guarantee that unauthorized third parties will not be able to circumvent our security measures and improperly collect, access, steal, or modify your information.

What are your rights? Depending on where you are located geographically, applicable privacy laws may grant you certain rights regarding your personal information. Unfortunately, privacy laws are sufficiently varied that these cannot be easily and comprehensively enumerated.

How do you exercise your rights? To exercise your privacy rights, please contact us via email at legal@genaflow.com. We will consider and act upon any request in accordance with applicable data protection laws. Depending on the request we may require additional information or steps to validate your identity before we are able to act on your request.

## II. Data Collected
We respect your privacy and avoid collecting extraneous and unnecessary information about our users. However, as part of the operation of any networked service and the operation of an effectively-run business, we do collect some information that is considered personal information under the laws of various jurisdictions. The specific data collected depends on how you interact with us, our Site, and our Services, the choices you make, and the products and features you use. The lists below identify data collected through various channels. Data types annotated with a ‘(+)’ indicate that this data is automatically provided as a function of the networking protocols without interaction from the user. The below lists do not include data types which we do not anticipate a user to provide, but which they nonetheless may enter into a free-form text field.

### a. Data Collected by Channel
#### All Interactions with the Site

- IP Address(+)
- Browser and/or Device Information(+)
#### Interactions via Email
- IP Address(+)
- Browser and/or Device Information(+)
- Email Address
- Name
#### Interactions with with Sales or Marketing
- Email Address
- Name
- Telephone Number
- Job Title
- Contact Preferences
- Contact Data
#### Use of the Services
- IP Address(+)
- Browser and/or Device Information(+)
- Cookie Data(+)
- Email Address
- Name
- Telephone Number
- Job Title
- Contact Preferences
- Contact Data
- Billing Address
- Payment Information
### b. Payment Information
Please note that any payment card data that is collected, including card numbers, cardholder names, billing addresses, and card verification codes are exclusively stored and processed by Stripe in accordance with the security and privacy requirements of the payment card issuers and are subject to the [Stripe Privacy Policy](https://stripe.com/privacy). Other payment information not related to payment cards is collected, stored, and processed by us in accordance with this policy.

### c. IP Addresses and Browser/Device Information
As a function of the network protocols in use, we automatically collect certain information when you visit, use, or navigate the Site or Services, or interact with us in any way via electronic means. This information, including your IP address and application-layer headers, does not reveal your specific identity (like your name or contact information) but may include device and usage information, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, geolocation information, information about how and when you use our Site, and other technical information. As noted before, this information is automatically transmitted as part of the network protocols that enable your connection. This information is primarily required to offer you the Site and Services, to maintain the security and operation of our Site and Services, and for our internal analytics and reporting purposes. We do also use cookies for the purposes of identifying user sessions within our Services.

## III. Data Processing
We process your personal information for a variety of reasons, depending on how you interact with our Site and Services, including:

- to facilitate account creation and authentication and otherwise manage user accounts;
- to deliver and facilitate delivery of services to the user;
- to respond to user inquiries/offer support to users;
- to send administrative information to you;
- to fulfill and manage your orders and payment;
- to request feedback;
- to send you marketing and promotional communications;
- to protect our Site and Services;
- to identify usage trends;
- to determine the effectiveness of our marketing and promotional campaigns;
- to save or protect an individual’s vital interest.
## IV. Legal Basis for Processing
We only process your personal data when legally authorized to do so. The remainder of this section of our Privacy Policy applies exclusively to users from the below-identified regions where relevant privacy laws require a specific explanation of our legal authorization to do so using specific terminology.

### a. GDPR - European Union and United Kingdom Users
For our users in the EU and the UK, we may rely on the following legal bases when processing your personal data:

- Performance of a Contract: This is the primary basis for our processing of personal data as part of our services. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
- Consent: We may process your information if you have given us permission to use your personal information for a specific purpose. You can withdraw your consent at any time. Please contact us if you would like to withdraw your consent for data processing.
- Legal Obligations: We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
- Legitimate Interests: We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
  - send users information about special offers and discounts on our products and services;
  - analyze how our services are used so we can improve them to engage and retain users;
  - support our marketing activities;
  - diagnose problems and/or prevent fraudulent activities;
- understand how our users use our products and services so we can improve user experience.
- Vital Interests: We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
### b. PIPEDA - Canadian Users
For our users in Canada, we may rely on the following legal bases when processing your personal data:

- Consent: We may process your information if you have given us specific permission to use your personal information for a specific purpose (“express consent”), or in situations where your permission can be inferred (“implied consent”). You can withdraw your consent at any time. lease contact us if you would like to withdraw your consent for data processing.
- Without Consent: Under Canadian law we may also process your data in certain situations without your express or implied consent, such as:
  - if collection is clearly in the interests of an individual and consent cannot be obtained in a timely way;
  - for investigations and fraud detection and prevention;
  - for business transactions provided certain conditions are met;
  - if we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse;
  - if it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province;
  - if disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records;
  - if it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced;
  - if the information is publicly available and is specified by the regulations.
## V. Sharing of Personal Data
All data collection, storage, and processing is performed solely for our own benefit. We do not sell your data to any third parties. However, we do utilize a number of third-party vendors, service providers, contractors, or agents (“third parties”) who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. We may also, from time to time, share your information with our business partners to offer you certain products, services, or promotions. Finally, in the event of a relevant business transaction, we may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

### Data Processors:
- Amazon Web Services
- Github
- Google
- Stripe

## VI. Cookies
We may use cookies and similar tracking technologies to access and store information about your user session in our Services. We do not use advertising cookies or other technologies to track your usage of third party sites and do not embed advertising trackers on our Site or Services to allow others to track your usage.

## VII. Federated Login
Our Services offer you the ability to register and log in using your third-party account credentials. Where you choose to do this, we will receive certain profile information about you from your account provider.The profile information we receive may vary depending on the provider concerned, but will often include your name, email address, avatar, and other information that you may have provided to that platform.

We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party identity provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.

## VIII. Notice of International Data Transfer
Please be advised the Site and Services are hosted in the United States. If you access the Site or Services from the European Union, Asia, or any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Site, you are transferring your data to the United States, and you expressly consent to have your data transferred to, stored in, and processed in the United States.

If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.

We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.

## IX. Data Retention
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than sixty (60) months past the termination of the user’s account.

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

## X. Data Access, Review, Updates, and Corrections
Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at legal@genaflow.com.

# XI. Data Security
We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Site and Services is at your own risk. You should only access the Site and Services within a secure environment.

## XII. Data Collection from Minors
We do not knowingly solicit data from or market to children under 18 years of age or under the age of majority in any jurisdiction where it may be higher. By using the Site and/or Services, you represent that you are at least 18 and have reached the age of majority in your jurisdiction. If we learn that personal information from minors has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from minor children, please contact us at legal@genaflow.com.

## XIII. Additional Privacy Rights
If you are a resident of certain regions, including the EU, UK, and Canada, you may have additional privacy rights through applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To exercise these rights, please contact us at legal@genaflow.com. We will consider and act upon any request in accordance with applicable data protection laws.

If you are subject to the General Data Protection Regulation (GDPR), and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here.

If you are located in Switzerland, the contact details for the data protection authorities are available here.

### a. Withdrawing Consent
If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us at legal@genaflow.com. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

### b. Marketing Communications Opt-Out
In compliance with relevant regulations, you can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us at legal@genaflow.com. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.

### c. Account Information
f you would at any time like to review or change the information in your account or terminate your account, you can either update that information in your account’s settings page after logging in or contact us using the registered address for your account. Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.

### d. Cookies
Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this will invalidate any active session you have with our Services, or prevent you from logging in to our services, respectively.

If you have questions or comments about your privacy rights, you may email us at legal@genaflow.com.

# XIV. Do-Not-Track
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

# XV. California Privacy Rights
California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).

The California Code of Regulations defines a “resident” as:

every individual who is in the State of California for other than a temporary or transitory purpose and
every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
All other individuals are defined as “non-residents.” If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your personal information.

### a. Data Types Collected (Last 12 Months)
We have collected the following categories of personal information in the past twelve (12) months:

- A. Identifiers: YES. (Category includes: contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name)
- B. Personal Information Categories Listed in the California Customer Records Statute: YES (Category includes: name, contact information, education, employment, employment history, and financial information)
- C. Protected Classification Characteristics under California or Federal Law: NO (Category includes: gender and date of birth)
- D. Commercial Information: YES (Category includes: transaction information, purchase history, financial details, and payment information)
- E. Biometric Information: NO (Category includes: fingerprints and voiceprints)
- F. Internet or Other Similar Network Activity: YES (Category includes: browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements)
- G. Geolocation Information: YES (Category includes: device location)
- H. Audio, Electronic, Visual, Thermal, Olfactory, or Similar Information: NO (Category includes: images and audio, video or call recordings created in connection with our business activities)
- I. Professional or Employment-Related Information: YES (Category includes: business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us)
- J. Education Information: NO (Category includes: student records and directory information)
- K. Inferences Drawn From Other Personal Information: YES (Category includes: inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics)
We may also collect other personal information outside of these categories through instances where you interact with us in person, online, by phone, or by mail for the purposes of:

receiving help through our customer support channels;
interacting with our sales and marketing staff;
participation in customer surveys or contests;
facilitation in the delivery of our Services and to respond to your inquiries.
### b. Usage and Sharing of Personal Information
More information about our data collection and sharing practices can be found in this privacy notice. You may contact us by email at legal@genaflow.com with any questions about these practices. If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf. We may also use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be “selling” of your personal information.

We have not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We have disclosed the following categories of personal information, as enumerated above, to third parties for a business or commercial purpose in the preceding twelve (12) months:

- A. Identifiers
- B. Personal Information Categories Listed in the California Customer Records Statute
- D. Commercial Information
- F. Internet or Other Similar Network Activity
- G. Geolocation Information
- I. Professional or Employment-Related Information
- K. Inferences Drawn From Other Personal Information
## c. Privacy Rights
### Right to Deletion
You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.

### Right to Know
Depending on the circumstances, you have a right to know:

- whether we collect and use your personal information;
- the categories of personal information that we collect;
- the purposes for which the collected personal information is used;
- whether we sell your personal information to third parties;
- the categories of personal information that we sold or disclosed for a business purpose;
- the categories of third parties to whom the personal information was sold or disclosed for a business purpose;
- the business or commercial purpose for collecting or selling personal information.
In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.

### Right to Non-Discrimination
We will not discriminate against you if you exercise your privacy rights.

### Other Rights
You also have several other rights under California law. These rights include:

- the right to object to the processing of your personal information;
- the right to request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information;
- the right to designate an authorized agent to make a request under the CCPA on your behalf. Please be aware that we may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA;
- the right to request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.
To exercise these rights, you can contact us by email at legal@genaflow.com. If you have any complaints about how we handle your data, we would like to hear from you.

#### Verification
Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.

We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.

## XVI. Privacy Policy Updates
We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

## XVII. Privacy Contact
If you have questions or comments about this notice, you may email us at legal@genaflow.com. You may also contact us via postal mail at:<p>

GenaFlow, LLC.\\
ATTN: Privacy Contact\\
137 N Oak Park Ave STE 215 #438\\
OAK PARK, IL 60301\\
United States of America`;

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="m">
      <Markdown remarkPlugins={[gfm]} children={pp_header }/>
        <Box
          sx={{
            background:'#e4e4f2',
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            width: 's'
          }}
        >
        <Markdown remarkPlugins={[gfm]} children={pp_toc }/>
        </Box>
        <Markdown remarkPlugins={[gfm]} children={pp_main }/>
        </Container>
    </ThemeProvider>
  );
  }
}

export default Privacy;