import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import styles from '../mystyle.module.css';
import SvgFlowChartWLogo from  '../images/FlowChartWLogo';
import SwipeableTextMobileStepper from '../components/UseCase'
import { TypeAnimation } from 'react-type-animation';
import BakerImage from '../images/BakerD.jpg'
import AnguloImage from '../images/AnguloB.jpg'
    import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
class Company extends React.Component {

  render(){

    return (
    <Stack direction="column"  alignItems="left" flexWrap="wrap" sx={{ml:5}} >
    <Typography sx={{fontWeight: 500, fontSize: 'clamp(2.0rem, 1.9643rem + 1.4vw, 3.5rem)', mt: 2, ml:1,  borderRadius: '10px', color: 'white', bgcolor: '#88154f', width: 'fit-content'}} align="left"  component={'span'}  display='inline'>
             The Team
       </Typography>

       <Card  style={{borderRadius: "25px", margin: "10px", width: "75%"}}>
      <CardContent style={{padding: "10px 10px 5px 10px" }}>
      <div style={{ padding: "10px 10px 5px 10px" }}>
      <Stack direction="row"  alignItems="left"  justifyContent='center'  flexWrap="wrap" useFlexGap   spacing={0}>
       <img src={BakerImage} alt="BakerDa" style={{borderRadius: "25px", margin: "10px 10px 5px 10px" }}  width='300'height='300' />
       <Stack direction="column"  alignItems="left"  justifyContent='center'  margin="10px 10px 5px 10px" flexWrap="wrap" useFlexGap   spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
       <Typography sx={{fontWeight: 400, fontSize: 'clamp(1.5rem, 1.5rem + .4vw, 2.5rem)'}} align="left"  component={'span'} >
           David Baker
        </Typography><Typography sx={{fontWeight: 400, color: '#88154f', fontSize: 'clamp(1.25rem, 1.25rem + .4vw, 1.75rem)'}} align="left"  component={'span'} >
           Founder
        </Typography>
        <Typography sx={{fontWeight: 400, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.0rem)'}} align="left"  component={'span'} >
        David has dedicated his 14 year career to building scaling big data pipelines for high-volume manufacturing, electronic health care records, and computational bioinformatics.  He also spent 10 years developing software and deploying remote instrumentation systems.
        David has spent years shepherding pipelines and troubleshooting IT related issues.  David developed GenaFlow capture his experience in order to minimize the effort operators and labs need to get their results.</Typography>
          </Stack>
        </Stack>
      </div>
      </CardContent>
      </Card>
      <Card  style={{borderRadius: "25px", margin: "10px", width: "75%"}}>
      <CardContent style={{padding: "10px 10px 5px 10px" }}>
      <div style={{ padding: "10px 10px 5px 10px" }}>
      <Stack direction="row"  alignItems="left"  justifyContent='center'  flexWrap="wrap" useFlexGap   spacing={0}>
       <img src={AnguloImage} alt="BakerDa" style={{borderRadius: "25px", margin: "10px 10px 5px 10px" }}  width='300'height='300' />
       <Stack direction="column"  alignItems="left"  justifyContent='center'  margin="10px 10px 5px 10px" flexWrap="wrap" useFlexGap   spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
       <Typography sx={{fontWeight: 400, fontSize: 'clamp(1.5rem, 1.5rem + .4vw, 2.5rem)'}} align="left"  component={'span'} >
           Benjamin Angulo
        </Typography><Typography sx={{fontWeight: 400, color: '#88154f', fontSize: 'clamp(1.25rem, 1.25rem + .4vw, 1.75rem)'}} align="left"  component={'span'} >
           Advisory Board
        </Typography>

        <Typography sx={{fontWeight: 400, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.0rem)'}} align="left"  component={'span'} >
        Benjamin is an experienced technical solutions engineer with a proven track record of over 6 years in healthcare technology, integration and digital transformations.
        Benjamin passionately collaborates with customers to tackle intricate challenges and craft tailored solutions that meet their needs. Benjamin has improved EHR laboratory workflows for countless customers and wants to help researchers improve their computational workflows through GenaFlow.
      </Typography>
          </Stack>
        </Stack>
      </div>
      </CardContent>
      </Card>
            </Stack>
    );
    }
}

export default Company;