import * as React from "react";
const SvgTemplateSub = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 185.208 111.125"
    {...props}
  >
    <rect
      width={177.011}
      height={13.568}
      x={4.099}
      y={3.308}
      ry={3}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.411475,
      }}
    />
    <rect
      width={41.471}
      height={82.862}
      x={4.233}
      y={21.273}
      ry={3}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.492184,
      }}
    />
    <rect
      width={129.782}
      height={21.597}
      x={50.76}
      y={21.273}
      ry={3}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.444514,
      }}
    />
    <rect
      width={129.782}
      height={19.912}
      x={50.76}
      y={46.834}
      ry={2.766}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.426821,
      }}
    />
    <rect
      width={129.782}
      height={33.362}
      x={50.76}
      y={70.772}
      ry={4.634}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.552474,
      }}
    />
    <rect
      width={23.753}
      height={6.183}
      x={10.64}
      y={7.457}
      ry={1.643}
      style={{
        fill: "#969696",
        fillOpacity: 1,
        strokeWidth: 0.264583,
      }}
    />
    <rect
      width={19.096}
      height={2.778}
      x={157.73}
      y={6.598}
      ry={0.738}
      style={{
        fill: "#969696",
        fillOpacity: 1,
        strokeWidth: 0.159004,
      }}
    />
    <rect
      width={19.096}
      height={2.778}
      x={157.73}
      y={10.963}
      ry={0.738}
      style={{
        fill: "#969696",
        fillOpacity: 1,
        strokeWidth: 0.159004,
      }}
    />
    <circle
      cx={16.456}
      cy={10.41}
      r={1.25}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.165914,
      }}
    />
    <circle
      cx={22.66}
      cy={10.41}
      r={1.25}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.165914,
      }}
    />
    <circle
      cx={28.907}
      cy={10.41}
      r={1.25}
      style={{
        fill: "#d8d8d8",
        fillOpacity: 1,
        strokeWidth: 0.165914,
      }}
    />
    <path
      d="M7.994 42.124H37.04v2.962H7.994zM54.661 24.874h29.046v2.962H54.661zM55.247 50.214h29.046v2.962H55.247z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.222337,
        strokeDasharray: ".889353,.222337",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M55.247 75.554h70.461v2.962H55.247z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.346289,
        strokeDasharray: "1.38516,.346289",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M7.994 46.895H37.04v2.962H7.994z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.222337,
        strokeDasharray: ".889353,.222337",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M7.977 51.65h20.519v2.996H7.977z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.187954,
        strokeDasharray: ".75182,.187954",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M7.977 56.234h30.409v2.957H7.977z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.2273,
        strokeDasharray: ".909205,.2273",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M8.129 61.211h29.046v2.962H8.129zM8.129 65.982h29.046v2.962H8.129z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.222337,
        strokeDasharray: ".889353,.222337",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M8.111 70.737H28.63v2.996H8.111z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.187954,
        strokeDasharray: ".75182,.187954",
        strokeOpacity: 1,
      }}
    />
    <path
      d="M8.111 75.321H38.52v2.957H8.111z"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0.2273,
        strokeDasharray: ".909205,.2273",
        strokeOpacity: 1,
      }}
    />
  </svg>
);
export default SvgTemplateSub;
