import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
//const useStyles = makeStyles((theme) => ({
//  form: {
//    display: 'flex',
//    flexDirection: 'column',
//    gap: theme.spacing(2),
//    maxWidth: '400px',
//    margin: 'auto',
//    padding: theme.spacing(2),
//    border: `1px solid ${theme.palette.divider}`,
//    borderRadius: theme.shape.borderRadius,
//  },
//}));

const CommentsForm = ({ onSubmit }) => {
  // const classes = useStyles();
  const [full_name, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
//    console.log(full_name, email, company, comment)
    if (!email.trim() || !full_name.trim() || !comment.trim() ) return;
    onSubmit({ full_name, email, company, comment });
    setFullName('');
    setCompany('');
    setEmail('');
    setComment('');
  };

  return (
    <form  onSubmit={handleSubmit} >

    <TextField
        margin="normal"
        required
        fullWidth
        name="email"
        label="email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="full_name"
        label="Full Name"
        variant="outlined"
        value={full_name}
        onChange={(e) => setFullName(e.target.value)}
        autoFocus
      />
      <TextField
        margin="normal"
        name="company"
        fullWidth
        label="Company"
        variant="outlined"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="Tell us your needs"
        label="Comment"
        rows={10}
        multiline = {true}
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        autoFocus
      />
      <Button type="submit" variant="contained" sx={{bgcolor:"#88154f", m:1}}  >
        Submit
      </Button>

    </form>
  );
};

export default CommentsForm;