import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import SvgQualityCloud from '../images/QualityCloud';
import SvgTemplateSub from '../images/TemplateSub';
import SvgSecure from '../images/Secure';
import Stack from '@mui/material/Stack';
import genomeResearch from '../images/genome-research-lab-test.jpg';
import styles from '../mystyle.module.css';

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 4

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  //<p  style={{textDecoration: "underline solid #88154f 50%"}}>
  return (
    <Box sx={{ width: '95%', flexGrow: 1 }}>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        <div  style={{   padding: "0px 0px 0px 0px" }}  key='1'>
        <Stack direction="row"  alignItems="center"  justifyContent='center' useFlexGap flexWrap="wrap" spacing={2}>
         <SvgTemplateSub className={styles['col-5'] + ' ' + styles['col-s-12']}/>
          <Stack direction="column"  alignItems="center"  justifyContent='center'  spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
            <Typography sx={{fontWeight: 500, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)', color:'#88154f',  ml:1,  borderRadius: '3px', bgcolor: '#e4e4f2', width: 'fit-content', fontFamily: 'Roboto Mono, monospace'}} align="left" >
              01 / 04
            </Typography>
             <Typography align="center" sx={{fontWeight: 500, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.8rem)', textDecoration: "underline solid #88154f 50%", mb:2}}>
              Pipelines made easy.
               </Typography>
              <Typography align="center" sx={{fontWeight: 300, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)'}}>
               Securely process data across workstations, clouds and even high-throughput computing clusters, simultaneously. GenaFlow's heuristic algorithms reliably decides how and where to process your data, to minimize costs and get your data faster. Its no-code workflows allow operators arrive the accurate information they need with minimal setup.
             </Typography>
          </Stack>
        </Stack>
      </div>
           <div style={{   padding: "0px 0px 0px 0px" }} key='2'>
           <Stack direction="row"  alignItems="center"  justifyContent='center' useFlexGap flexWrap="wrap" spacing={2}>
           <SvgSecure className={styles['col-5'] + ' ' + styles['col-s-12']}/>
            <Stack direction="column"  alignItems="center"  justifyContent='center'  spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
            <Typography sx={{fontWeight: 500, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)', color:'#88154f',  ml:1,  borderRadius: '3px', bgcolor: '#e4e4f2', width: 'fit-content', fontFamily: 'Roboto Mono, monospace'}} align="left" >
              02 / 04
            </Typography>
            <Typography align="center" sx={{fontWeight: 500, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.8rem)', textDecoration: "underline solid #88154f 50%", mb:2}}>
              Pipelines secured
              </Typography>
              <Typography align="center" sx={{fontWeight: 300, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)'}}>
               Process sensitive data with ease: admins control where pipelines run. Restrict data movement to secure protocols and approved hardware.
             </Typography>
          </Stack>

              </Stack>
          </div>
          <div  style={{   padding: "0px 0px 0px 0px" }} key='3'>
        <Stack direction="row"  alignItems="center"  justifyContent='center' useFlexGap flexWrap="wrap" spacing={2} >
          <img src={genomeResearch} alt="Genomic Research" className={styles['col-5'] + ' ' + styles['col-s-12']}/>
          <Stack direction="column"  alignItems="center"  justifyContent='center'  spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
            <Typography sx={{fontWeight: 500, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)', color:'#88154f',  ml:1,  borderRadius: '3px', bgcolor: '#e4e4f2', width: 'fit-content', fontFamily: 'Roboto Mono, monospace'}} align="left" >
              03 / 04
            </Typography>
            <Typography align="center" sx={{fontWeight: 500, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.8rem)', textDecoration: "underline solid #88154f 50%", mb:2}}>
            Your technical expert
              </Typography>
              <Typography align="center" sx={{fontWeight: 300, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)'}}>
               GenaFlow was written by leading experts the fields of Bioinformatics, Electonic Health Records Systems, Laboratory Management Systems, wastewater management, and even high-throughput manufacturing. GenaFlow will help you process and intuitively understand your data.
             </Typography>
          </Stack>
        </Stack>
      </div>
      <div  style={{   padding: "0px 0px 0px 0px" }} key='4'>
        <Stack direction="row"  alignItems="center"  justifyContent='center' useFlexGap flexWrap="wrap" spacing={2} >
          <SvgQualityCloud className={styles['col-5'] + ' ' + styles['col-s-12']}/>
          <Stack direction="column"  alignItems="center"  justifyContent='center'  spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
            <Typography sx={{fontWeight: 500, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)', color:'#88154f',  ml:1,  borderRadius: '3px', bgcolor: '#e4e4f2', width: 'fit-content', fontFamily: 'Roboto Mono, monospace'}} align="left" >
              04 / 04
            </Typography>
             <Typography align="center" sx={{fontWeight: 500, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.8rem)', textDecoration: "underline solid #88154f 50%", mb:2}}>
              Quality assured
               </Typography>
              <Typography align="center" sx={{fontWeight: 300, fontSize: 'clamp(.8rem, .8rem + .2vw, 1.4rem)'}}>
                GenaFlow’s proprietary algorithms realizes the full potential of the data and identifies patterns in your data before they become problems. GenaFlow creates reproducible and trackable pipelines. No more chasing down log files.
             </Typography>
          </Stack>

        </Stack>
      </div>
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >

            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft  style={{fill: "black"}}/>
            ) : (
              <KeyboardArrowRight style={{fill: "black"}} />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight  style={{fill: "black"}} />
            ) : (
              <KeyboardArrowLeft  style={{fill: "black"}} />
            )}

          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;