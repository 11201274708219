import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import styles from '../mystyle.module.css';
import SvgFlowChartWLogo from  '../images/FlowChartWLogo';
import SwipeableTextMobileStepper from '../components/UseCase'
import { TypeAnimation } from 'react-type-animation';
class Home extends React.Component {

  render(){

    return (
    <Stack direction="column"  alignItems="left" flexWrap="wrap" >
      <div style={{ padding: "25px 10px 5px 10px" }}>
      <Stack direction="row"  alignItems="left"  justifyContent='center'  flexWrap="wrap" useFlexGap   spacing={0}>
       <Stack direction="column"  alignItems="left"  justifyContent='center'  flexWrap="wrap" useFlexGap   spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>

       <Typography sx={{fontWeight: 500, fontSize: "clamp(2.0rem, 1.9643rem + 1.4vw, 3.5rem)", ml:1}} align="left"  component={'span'} >
      Your pipelines...
      </Typography>
      <Typography sx={{fontWeight: 500, fontSize: 'clamp(2.0rem, 1.9643rem + 1.4vw, 3.5rem)', ml:1,  borderRadius: '10px', color: 'white', bgcolor: '#88154f', width: 'fit-content'}} align="left"  component={'span'}  display='inline'>
             &lt;realized&gt;
       </Typography>
        <Typography sx={{fontWeight: 400, fontSize: 'clamp(1.0rem, 1rem + .4vw, 1.5rem)'}} align="left"  component={'span'} >
        Finally a platform that empowers your organization to run complex data pipelines. Process the data how and where you expect it: in the cloud, on-premise or both.
        </Typography>

        </Stack>
        <SvgFlowChartWLogo className={styles['col-5'] + ' ' + styles['col-s-12']}/>
        </Stack>
      </div>
      <div style={{ padding: "5px 10px 5px 10px" }}>
        <Stack direction="column"  alignItems="left"  justifyContent='center'  flexWrap="wrap" useFlexGap   spacing={0} className={styles['col-5'] + ' ' + styles['col-s-12']}>
      <TypeAnimation className={styles['col-5'] + ' ' + styles['col-s-12']}
            sequence={[

              'GenaFlow: Built for Researchers.',
              3000,
              'GenaFlow: Built for Data Teams.',
              3000,
              'GenaFlow: Built for IT Operations.',
              3000,
              'GenaFlow: Built for Collaboration.',
              3000,
              'GenaFlow: Built for your Organization.',
              6000,
            ]}
            speed={50}
            style={{color: '#88154f', fontSize: '1.5rem', background:'#e4e4f2', width: 'fit-content', padding: 2,  fontFamily: 'Roboto Mono, monospace', margin: 5}}
            repeat={Infinity}
          />

        </Stack>
      </div>
       <SwipeableTextMobileStepper/>
      </Stack>
    );
    }
}

export default Home;