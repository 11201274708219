import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme();

class SignIn extends React.Component {


  handleSubmit = async (event) => {
    event.preventDefault();
     const response_mod = await fetch('https://2ut9iv2fh6.execute-api.us-east-1.amazonaws.com/test/join-waitlist', {
            method: "POST",
            headers: { 'Accept': 'application/json',
            "Content-Type": "application/json" },
            body: JSON.stringify({'greeter': 'MYNAME'})
          });
            const result_json = await response_mod.json();
          if (result_json['success']){
            console.log('success')
            console.log(result_json)
          }else{
            console.log('fail')
            console.log(result_json)
          }

  };


  render(){

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#88154f",  width: 50, height: 50}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: 700, bgcolor: "#88154f" }}
            >
              Sign In
          </Button>
            <Grid container>
              <Grid item xs>
                  Customers: Sign in Credentials can be obtained through through your designated Administrators.
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  );
  }
}

export default SignIn;