import * as React from 'react';
import Avatar from '@mui/material/Avatar';
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
//import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
//import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CommentsForm from '../components/commentsForm'

const theme = createTheme();
// class Deposits extends React.Component {
class Contact extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        submitted: false,
      };
     };

  handleSubmit = async (data) => {
    // console.log(data)
    const response_mod = await fetch('https://2ut9iv2fh6.execute-api.us-east-1.amazonaws.com/beta/join-waitlist', {
            method: "POST",
            headers: { 'Accept': 'application/json',
            "Content-Type": "application/json"
    },
            body: JSON.stringify(data)
          });
            const result_json = await response_mod.json();
             this.setState(prevState => ({submitted: true}))

  };
//{!submitted ? (
//):()
  render(){
  // const _submitted = this.state.submitted
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#88154f",  width: 60, height: 60}}>
            <CommentRoundedIcon />
          </Avatar>
          {!this.state.submitted ? (
          <>
          <Typography component="h1" variant="h5">
            Contact Us
          </Typography>
          <CommentsForm onSubmit={this.handleSubmit} />
            <Grid container>
              <Grid item xs>
                 We're excited to share with you our capabilities and how they can help you reach your computational pipeline needs.  Please share your information and we will be in touch soon.
              </Grid>
            </Grid>


            </>
          ) : (
            <>
            <h2> Thank You.</h2>
            <p>Your request has been recieved</p>
            </>
            )}
        </Box>
      </Container>
    </ThemeProvider>
  );
  }
}

export default Contact;