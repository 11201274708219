import * as React from "react";
const SvgQualityCloud = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="qualityCloud_svg__svg1"
    viewBox="0 0 185.208 111.125"
    {...props}
  >
    <defs id="qualityCloud_svg__defs1">
      <marker
        id="qualityCloud_svg__RoundedArrow-0"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-4"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-3"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-0"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-3"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-4-0"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-3-5"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-0-9"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-3-4"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-4-0-7"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-3-5-7"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-0-9-5"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-3-4-4"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-4-0-7-3"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-3-5-7-6"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-0-9-5-8"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-3-4-4-0"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-1"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-0"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-8"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-5"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__RoundedArrow-0-5"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path8-4-8"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
      <marker
        id="qualityCloud_svg__marker4-8-6"
        markerHeight={1}
        markerWidth={1}
        orient="auto-start-reverse"
        preserveAspectRatio="xMidYMid"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
        viewBox="0 0 1 1"
      >
        <path
          id="qualityCloud_svg__path4-8-2"
          d="M-.211-4.106 6.21-.894a1 1 90 0 1 0 1.788L-.21 4.106A1.236 1.236 31.717 0 1-2 3v-6A1.236 1.236 148.283 0 1-.211-4.106"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "none",
          }}
          transform="scale(.7)"
        />
      </marker>
    </defs>
    <g id="qualityCloud_svg__layer1">
      <path
        id="qualityCloud_svg__path1"
        d="M108.936 45.715c-.4.18-.805.38-1.205.588-1.198.641-2.381 1.423-3.59 2.321l-2.58-2.69a21 21 0 0 1 2.878-2.045 20.5 20.5 0 0 1 2.546-1.287c.352-.16.704-.31 1.066-.451-1.7-2.744-4.145-4.711-6.916-5.926-2.77-1.209-5.871-1.675-8.892-1.408-3 .262-5.914 1.258-8.323 2.963-2.786 1.967-4.902 4.91-5.717 8.81l-.255 1.219-1.332.213q-1.96.315-3.496.86-1.487.526-2.648 1.292a8.5 8.5 0 0 0-1.603 1.36c-1.402 1.53-2.052 3.448-2.036 5.39.016 1.972.72 3.963 2.02 5.6a9.6 9.6 0 0 0 1.683 1.651c.65.49 1.38.899 2.2 1.205.816.306 1.716.524 2.713.65h38.655c1.88-.412 3.543-.97 4.955-1.69 1.401-.713 2.547-1.583 3.404-2.627 1.332-1.617 1.988-3.905 2.014-6.25.032-2.463-.628-4.935-1.912-6.732-.368-.52-.773-.986-1.2-1.403-1.923-1.89-4.363-2.715-6.899-2.73-1.827-.014-3.73.389-5.53 1.117m3.005-4.507a17 17 0 0 1 2.552-.175c3.58.025 7.033 1.19 9.75 3.861q.855.838 1.614 1.895c1.705 2.389 2.58 5.58 2.542 8.703-.037 3.064-.943 6.114-2.797 8.358-1.21 1.462-2.76 2.656-4.62 3.603-1.79.914-3.879 1.608-6.223 2.103l-.437.049H75.385l-.245-.02c-1.423-.165-2.712-.47-3.884-.912a12.6 12.6 0 0 1-3.224-1.763 13 13 0 0 1-2.344-2.293c-1.78-2.234-2.739-4.963-2.76-7.683-.022-2.749.916-5.483 2.94-7.698a12.2 12.2 0 0 1 2.313-1.962q1.542-1.019 3.538-1.724a23 23 0 0 1 2.93-.816c1.173-4.244 3.672-7.513 6.884-9.781 3.005-2.122 6.623-3.36 10.347-3.686 3.709-.326 7.524.247 10.95 1.748 3.735 1.637 6.995 4.361 9.11 8.193"
        style={{
          fill: "#88154f",
          fillOpacity: 1,
          strokeWidth: 0.508687,
        }}
      />
      <text
        xmlSpace="preserve"
        id="qualityCloud_svg__text1"
        x={76.438}
        y={58.485}
        style={{
          fontSize: "9.65594px",
          fill: "#000",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.720774,
          strokeOpacity: 1,
        }}
      >
        <tspan
          id="qualityCloud_svg__tspan1"
          x={76.438}
          y={58.485}
          style={{
            fill: "#000",
            fillOpacity: 1,
            stroke: "#000",
            strokeWidth: 0.720774,
            strokeOpacity: 1,
          }}
        >
          {"QUALITY"}
        </tspan>
      </text>
      <path
        id="qualityCloud_svg__path1-1"
        d="M96.002 86.628h2.964a.293.293 0 0 1 .293.293v11.643a.293.293 0 0 1-.293.293h-2.964a.293.293 0 0 1-.293-.293V86.909a.293.293 0 0 1 .293-.293Zm10.225 15.938H87.844a1.45 1.45 0 0 1-1.454-1.448V84.566h1.859v16.14h17.978zm-4.742-10.12h2.963a.293.293 0 0 1 .293.293v5.825a.293.293 0 0 1-.293.293h-2.963a.293.293 0 0 1-.293-.293v-5.832a.293.293 0 0 1 .293-.293zM90.52 88.878h2.964a.293.293 0 0 1 .285.286v9.4a.293.293 0 0 1-.285.293h-2.964a.293.293 0 0 1-.293-.293v-9.4a.293.293 0 0 1 .293-.286"
        style={{
          strokeWidth: 0.146484,
        }}
      />
      <g
        id="qualityCloud_svg__g1"
        style={{
          clipRule: "evenodd",
          fillRule: "evenodd",
          imageRendering: "optimizeQuality",
          shapeRendering: "geometricPrecision",
          textRendering: "geometricPrecision",
        }}
        transform="matrix(.05047 0 0 .05047 10.73 34.061)"
      >
        <path
          id="qualityCloud_svg__path1-7"
          d="M31.179 0H480.82C497.972 0 512 14.028 512 31.182v294.257c0 17.151-14.028 31.179-31.18 31.179H31.179C14.028 356.618 0 342.59 0 325.439V31.182C0 14.028 14.028 0 31.179 0M56.11 194.421c0-28.707 11.639-54.7 30.451-73.515 18.814-18.812 44.807-30.451 73.514-30.451 21.077 0 40.685 6.274 57.07 17.052l-10.471 9.365a89.94 89.94 0 0 0-40.929-12.733v11.993h-16.401v-11.537c-17.969 2.125-34.321 9.511-47.476 20.577l8.988 8.987-11.531 11.593-8.972-8.971c-12.95 15.651-20.736 35.736-20.736 57.64zm248.377-2.479h10.04v-76.795a2.41 2.41 0 0 1 2.408-2.405h24.887a2.41 2.41 0 0 1 2.407 2.405v76.795h18.269V98.29a2.41 2.41 0 0 1 2.407-2.404h24.875a2.41 2.41 0 0 1 2.407 2.404v93.652h18.27v-46.825c0-1.325 1.081-2.419 2.404-2.419h24.89a2.41 2.41 0 0 1 2.407 2.407v46.837h8.978v9.102H304.487ZM59.474 235.126h393.052v14.444H59.474Zm0 42.986h348.034v15.422H59.474Zm179.767-151.081c15.462 18.148 24.8 41.682 24.8 67.39h-13.508c0-21.448-7.469-41.156-19.939-56.662zM36.595 57.637h438.813c9.685 0 17.607 7.925 17.607 17.609v243.968c0 9.688-7.922 17.607-17.607 17.607H36.595c-9.685 0-17.607-7.919-17.607-17.607V75.246c0-9.684 7.922-17.609 17.607-17.609"
        />
        <path
          id="qualityCloud_svg__path2"
          fill="#ef1c25"
          d="m234.063 105.745-83.197 74.405c-15.799 13.054 5.333 35.025 19.59 16.321l69.185-85.836z"
        />
      </g>
      <path
        id="qualityCloud_svg__path1-4"
        d="M26.14 85.355a1.611 1.611 0 1 1 0 3.223 1.611 1.611 0 0 1 0-3.223m5.984-3.625H26.86v2.303a.72.72 0 0 1-1.438 0v-2.807q0-.055.007-.107a.72.72 0 0 1 .712-.826h5.945l-.024-1.934a4 4 0 0 1-.833-.349l-.447.492a.43.43 0 0 1-.303.134.44.44 0 0 1-.312-.11l-.657-.615a.44.44 0 0 1-.142-.304.4.4 0 0 1 .117-.304l.491-.53a3.5 3.5 0 0 1-.353-.839l-.656.027a.42.42 0 0 1-.314-.117.43.43 0 0 1-.137-.3l-.036-.899a.42.42 0 0 1 .113-.313.42.42 0 0 1 .303-.138l.717-.028a3.7 3.7 0 0 1 .348-.838l-.484-.446a.429.429 0 0 1-.025-.617l.603-.656a.44.44 0 0 1 .303-.143.4.4 0 0 1 .305.118l.54.489c.122-.073.253-.144.393-.202q.21-.088.432-.151l-.026-.656a.42.42 0 0 1 .117-.314.43.43 0 0 1 .3-.138l.899-.033a.42.42 0 0 1 .313.112.42.42 0 0 1 .138.304l.028.716a4 4 0 0 1 .833.349l.447-.484a.429.429 0 0 1 .617-.025l.009.008.656.603a.44.44 0 0 1 .142.303.4.4 0 0 1-.117.305l-.49.54c.074.127.144.257.203.4q.087.215.15.428l.657-.026a.42.42 0 0 1 .313.117.43.43 0 0 1 .138.3l.035.899a.42.42 0 0 1-.113.313.42.42 0 0 1-.303.138l-.716.028a4 4 0 0 1-.349.833l.492.447a.43.43 0 0 1 .134.303.44.44 0 0 1-.11.312l-.615.658a.4.4 0 0 1-.3.142.4.4 0 0 1-.308-.117l-.53-.49a3.5 3.5 0 0 1-.839.352l.022 1.943h5.93a.72.72 0 0 1 .712.826 1 1 0 0 1 .008.107v2.807a.72.72 0 0 1-1.439 0v-2.3H33.85v2.073a.863.863 0 0 1-1.726 0zm.736-8.463c.243-.01.471.026.694.11q.326.127.579.354.246.23.398.548c.103.215.155.44.166.683.01.243-.027.471-.11.694q-.128.326-.355.579a1.8 1.8 0 0 1-.548.398q-.317.151-.682.166a1.7 1.7 0 0 1-.695-.11 1.9 1.9 0 0 1-.578-.355 1.8 1.8 0 0 1-.399-.547 1.7 1.7 0 0 1-.165-.683c-.01-.243.026-.472.11-.695q.127-.327.354-.578.23-.247.548-.399a1.7 1.7 0 0 1 .683-.165m6.923 12.088a1.611 1.611 0 1 1 0 3.223 1.611 1.611 0 0 1 0-3.223m-6.85 0a1.611 1.611 0 1 1 0 3.223 1.611 1.611 0 0 1 0-3.223"
        className="qualityCloud_svg__st0"
        style={{
          strokeWidth: 0.146493,
        }}
      />
      <path
        id="qualityCloud_svg__path1-97"
        d="m165.07 77.266-.363-.364a.28.28 0 1 1 .397-.397l.725.725a7.5 7.5 0 0 0 1.717-.338l-2.315-2.315a.28.28 0 1 1 .397-.397l2.494 2.494a7.2 7.2 0 0 0 1.283-.722l-3.047-3.047a.28.28 0 1 1 .397-.397l3.101 3.1a10 10 0 0 0 .643-.573.28.28 0 0 1 .396.005.28.28 0 0 1-.006.396c-2.813 2.748-5.52 2.5-8.246 2.247-2.709-.249-5.44-.5-8.279 2.435a.28.28 0 0 1-.401-.39c2.779-2.873 5.423-2.883 8.04-2.662-.081-1.19-.093-2.41.209-3.68.325-1.366 1.01-2.778 2.363-4.254a.278.278 0 1 1 .411.377c-1.277 1.395-1.923 2.723-2.23 4.006-.29 1.23-.272 2.428-.188 3.601l.126.012.452.041c-.08-1.135-.088-2.27.195-3.425.302-1.228.932-2.463 2.148-3.708a.28.28 0 0 1 .401.39c-1.138 1.166-1.725 2.313-2.005 3.451-.273 1.108-.257 2.221-.175 3.337.455.033.907.056 1.36.052zm-5.364 6.116a.28.28 0 1 1-.397.397l-3.225-3.226a.28.28 0 0 1-.082-.205 9 9 0 0 0-.726.678.28.28 0 0 1-.403-.388c2.7-2.803 5.31-2.583 8.034-2.353 2.666.224 5.45.459 8.511-2.347a.278.278 0 1 1 .377.412c-2.859 2.62-5.454 2.74-7.935 2.572.106 1.25.157 2.508-.137 3.793-.32 1.396-1.039 2.808-2.526 4.247a.28.28 0 0 1-.395-.006.28.28 0 0 1 .006-.396c1.397-1.353 2.073-2.67 2.369-3.968.282-1.242.224-2.479.118-3.712l-.436-.037-.154-.013c.085 1.162.11 2.306-.163 3.459-.299 1.255-.948 2.503-2.254 3.763a.28.28 0 0 1-.389-.403c1.217-1.173 1.823-2.329 2.098-3.488.264-1.107.23-2.23.143-3.375a18 18 0 0 0-1.441-.061l.557.556a.28.28 0 1 1-.397.397l-.775-.775a.28.28 0 0 1-.08-.153c-.599.042-1.193.143-1.788.34l2.457 2.458a.28.28 0 1 1-.397.397l-2.519-2.517a.3.3 0 0 1-.074-.133 6.7 6.7 0 0 0-1.344.785q.079.016.141.076z"
        style={{
          strokeWidth: 0.146492,
        }}
      />
      <path
        id="qualityCloud_svg__path1-8"
        d="m129.33 7.124-.33-.647-1.635.86.316.649.307-.16a.2.2 0 0 1 .054-.04l.43-.213zm-.721 3.3a6.2 6.2 0 0 0-1.607.702c-.957.59-1.735 1.436-2.192 2.45-.455 1.012-.59 2.194-.262 3.466.147.568.387 1.157.733 1.756.607-.58 1.3-.948 2.015-1.128-.322-.602-.492-1.175-.534-1.702a2.87 2.87 0 0 1 .326-1.614 3 3 0 0 1 1.112-1.133c.444-.263.971-.44 1.55-.512zm1.334 2.667a3.7 3.7 0 0 0-1.536.474 2.6 2.6 0 0 0-.964.979c-.217.4-.321.87-.28 1.393.04.504.212 1.058.546 1.65q.194-.03.386-.041a4.46 4.46 0 0 1 2.45.566h5.66a.2.2 0 0 1 .2.201v1.667a.2.2 0 0 1-.2.2h-3.758c.214.471.359.994.414 1.565h3.963c.05 0 .1.018.137.056l1.939 1.846a.2.2 0 0 1-.127.356h-16.188V24a.2.2 0 0 1-.155-.327l1.476-1.8a3.9 3.9 0 0 1 .307-1.523c.186-.447.452-.862.783-1.25a7.5 7.5 0 0 1-.835-1.961c-.353-1.367-.206-2.64.285-3.73.489-1.088 1.322-1.992 2.346-2.625a6.7 6.7 0 0 1 1.635-.727l-.126-.25-.01-.02a.2.2 0 0 1 .095-.267l.243-.116-.583-1.16-.348.181q-.01.007-.02.01a.2.2 0 0 1-.268-.092l-.49-1.007a.2.2 0 0 1 .086-.265l1.978-1.04.011-.008c.099-.05.22-.01.27.088l.513 1.005a.2.2 0 0 1-.087.268l-.263.138.63 1.2.28-.135a.2.2 0 0 1 .264.088l2.469 4.715.24-.127a.2.2 0 0 1 .271.083l.55 1.046.003.002a.2.2 0 0 1-.087.27l-2.922 1.511-.006.003a.2.2 0 0 1-.268-.094l-.498-1.028a.2.2 0 0 1 .086-.267l.279-.148zm3.742 1.314-.364-.689-2.125 1.127-.117.061-.004.003-.006.003-.271.144.325.672zm-2.612.05.136-.072 1.507-.804-2.377-4.541-1.199.57-.15.076-.03.011-.217.104zm-2.082-5.221.023-.012.677-.335-.614-1.17-.45.225-.225.117zm2.152 9.28a4.5 4.5 0 0 1 1.096 1.266h3.763v-1.266zm-5.732.72q-.008.013-.02.024a4.5 4.5 0 0 0-.81 1.248 3.5 3.5 0 0 0-.272 1.24h8.15c-.116-1.11-.598-2.02-1.281-2.671a4.1 4.1 0 0 0-1.418-.883 4.05 4.05 0 0 0-1.64-.245c-.959.057-1.92.464-2.71 1.286m7.3 2.914-.023.003a.2.2 0 0 1-.046-.003H124.2l-1.193 1.455h15.263l-1.528-1.455z"
        style={{
          strokeWidth: 0.146488,
        }}
      />
      <path
        id="qualityCloud_svg__path1-6"
        d="M162.884 30.398c4.229 0 7.657 1.606 7.657 3.584 0 1.979-3.43 3.583-7.657 3.583s-7.657-1.606-7.657-3.583 3.43-3.584 7.657-3.584m-7.657 11.988v2.75c1.362 4.839 14.822 3.904 15.315-.247v-2.748c-.672 4.557-14.238 4.885-15.315.245m-.038-7.162v2.686c1.362 4.726 14.896 4.087 15.391.034V35.26c-.673 4.452-14.314 4.497-15.391-.035m0 3.472v2.75c1.362 4.839 14.896 4.186 15.391.034v-2.748c-.673 4.557-14.314 4.604-15.391-.036"
        className="qualityCloud_svg__st0"
        style={{
          strokeWidth: 0.146486,
        }}
      />
      <g
        id="qualityCloud_svg__g2"
        style={{
          clipRule: "evenodd",
          fillRule: "evenodd",
          imageRendering: "optimizeQuality",
          shapeRendering: "geometricPrecision",
          textRendering: "geometricPrecision",
        }}
        transform="matrix(.03516 0 0 .03516 44.78 6.532)"
      >
        <path
          id="qualityCloud_svg__path1-3"
          d="M35.95 0h213.36v146.7c0 5.51 4.47 9.98 9.98 9.98H414v319.39c0 9.83-4.04 18.81-10.55 25.34l-.08.08c-6.53 6.49-15.49 10.52-25.31 10.52H35.95c-9.9 0-18.89-4.04-25.4-10.55S0 485.96 0 476.07V35.94c0-9.89 4.04-18.88 10.55-25.39S26.05 0 35.95 0m233.33 2.23a13.2 13.2 0 0 1 2.49 2.17l136.55 132.31H269.28ZM91.31 419.73c-5.52 0-9.99-4.47-9.99-9.98s4.47-9.99 9.99-9.99h231.38c5.52 0 9.99 4.48 9.99 9.99s-4.47 9.98-9.99 9.98zm0-76.35c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h227.78c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h184.31c5.51 0 9.99 4.47 9.99 9.98 0 5.52-4.48 9.99-9.99 9.99zm0-76.36c-5.52 0-9.99-4.47-9.99-9.98 0-5.52 4.47-9.99 9.99-9.99h102.9c5.52 0 9.99 4.47 9.99 9.99 0 5.51-4.47 9.98-9.99 9.98zm0-76.35c-5.52 0-9.99-4.47-9.99-9.99 0-5.51 4.47-9.98 9.99-9.98h55.41c5.52 0 9.99 4.47 9.99 9.98 0 5.52-4.47 9.99-9.99 9.99z"
        />
      </g>
      <path
        id="qualityCloud_svg__path3-7"
        d="m64.35 26.182 6.576 6.019"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0)",
          markerEnd: "url(#qualityCloud_svg__marker4-8)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-7"
        d="m117.024 26.71-6.198 6.407"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-5)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-6)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-4"
        d="m96.148 73.592.138 8.913"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-1)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-8)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-2"
        d="m45.248 43.981 8.576 2.43"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-4)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-0)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-2-2"
        d="m135.7 65.123 8.575 2.43"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-4-0)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-0-9)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-2-2-2"
        d="m133.402 46.544 8.496-2.696"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-4-0-7)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-0-9-5)",
        }}
      />
      <path
        id="qualityCloud_svg__path3-7-2-2-2-0"
        d="m50.374 70.252 8.496-2.697"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#000",
          strokeWidth: 0.543285,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeOpacity: 1,
          markerStart: "url(#qualityCloud_svg__RoundedArrow-0-4-0-7-3)",
          markerEnd: "url(#qualityCloud_svg__marker4-8-0-9-5-8)",
        }}
      />
    </g>
    <style id="style1" type="text/css">
      {".qualityCloud_svg__st0{fill-rule:evenodd;clip-rule:evenodd}"}
    </style>
  </svg>
);
export default SvgQualityCloud;
