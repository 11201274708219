import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainFrame } from "./frames/Frame";
// import { Dashboard } from "./pages/Dashboard";
// import { Login } from "./pages/Login";
import { ThemeProvider } from '@mui/material/styles';

import { createTheme } from '@mui/material/styles';


export default function App(){
const palette = {
  primary: {
    main: '#e4e4f2', // Example primary color
    dark: '#88154f'
  },
  secondary: {
    main: '#ff0000', // Example secondary color
  },
  background: {
    default: '#ffffff', // Example background color
  },
  text: {
    primary: '#000000'
  },
  // Add more colors as needed
};
const theme = createTheme({
  palette: {
    ...palette,
    mode: 'light', // Ensure it's a light theme
  },
});
//  sessionStorage.setItem("username", "=");
//  sessionStorage.setItem("user_uuid", "-");
  return (
  <ThemeProvider theme={theme}>
    <Routes>
      <Route path="/*" element={<MainFrame />} />
    </Routes>
    </ThemeProvider>
  );
};
