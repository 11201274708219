//import './App.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import Paper from '@mui/material/Paper';
//import ReactDOMServer from 'react-dom/server';
import MenuIcon from '@mui/icons-material/Menu';
import genalogo from '../images/gena2.png';
import '../mystyle.module.css';
// import Stack from '@mui/material/Stack';
export const NavBar = () => {

 const [anchorElNav, setAnchorElNav] = React.useState(null);
//  const [anchorElUser, setAnchorElUser] = React.useState(null);
 const pages = ['About', 'Company', 'Contact', 'Sign In'];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
//  const handleOpenUserMenu = (event) => {
//    setAnchorElUser(event.currentTarget);
//  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
     const id=event.target.id.replaceAll(/ /g,"_");
    event.preventDefault();
      window.location.href='/'+ id;
  };

//  const handleCloseUserMenu = () => {
//    setAnchorElUser(null);
//  };
  return(

  <AppBar position="sticky"   width="100%" sx={{
           margin: '0',
           padding: "0"}}  >
        <Container  width="100%" disableGutters >
          <Toolbar alignItems='left' sx={{
           margin: '0',
           padding: "0"}} disableGutters>
           <Box display='flex' flexGrow={1}>
          <Box sx={{
          height: { xs: "5rem", md: '8rem' }, margin: '0',
           width: { xs: "unset", md: 'unset' }
           } }
          component="img"
          alt="GenaFlow"

          src={genalogo}/>
               <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                id={page}
                 variant="h1"
                 onClick={(e) => handleCloseNavMenu(e)}
                className="menu-link"
              >
                {page}
              </Button>
            ))}

          </Box>
          </Box>
          <Box sx={{  display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"

              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon  style={{ color: '#88154f', width: 50, height: 50}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} id={page}  onClick={(e) => handleCloseNavMenu(e)}>
                  <Typography id={page}  color="#88154f" textAlign="center" sx={{ fontWeight: 700, fontSize: '1.6rem'}}>{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          </Toolbar>
        </Container>
      </AppBar>
        )
        }